/**
 * GROUPS IN HOMEBOX
 */
function openPopoverGroup() {
    if($('.popover-homebox').length == 0) return false;

    $('.popover-homebox').popover()
        .on("mouseenter", function () {
            var _this = this;
            $(this).popover("show");
            $(".popover").on("mouseleave", function () {
                $(_this).popover('hide');
            });
        }).on("click", function () {
            var _this = this;
            $(this).popover("toggle");
        }).on("mouseleave", function () {
            var _this = this;
            if (!$(".popover:hover").length) {
                $(_this).popover("hide");
            }
        });
}
openPopoverGroup();

window.addGroupFilter = function (valGroup, labelGroup, homeId) {
    valGroup = valGroup.replace("'", " ");
    labelGroup = labelGroup.replace("&perso;", "'");
    var find = false;

    $(".pbm input[type=checkbox]").each(function() {
        if (valGroup == $(this).val()) find = true;
    });
    if (!find) {
        if($(".list-homes, .alert-homes-list").length  > 0) {
            $(".panel.panel-default.fixed-map > .panel-body").last().after("<div class='pbm panel-body panel-filters-recherche'><a class='kill-filter grey-md-color' href='#''><span class='glyphicon glyphicon-remove'></span></a>" + labelGroup + '<div class="switch switch-new pull-right" data-on-label="<i class=\'fui-check\'>ON</i>" data-off-label="<i class=\'fui-cross\'>OFF</i>">' + "<input class='new-filter' type='checkbox' value='" + valGroup + "' name='filterCookie' /></div></div>");
            modifFilterPersoList();
            killFilter();
        } else {
            $(".fixed-filter-map").append("<div class='pbm panel-body panel-filters-recherche'><a class='kill-filter grey-md-color' href='#''><span class='glyphicon glyphicon-remove'></span></a>" + labelGroup + '<div class="switch switch-new pull-right" data-on-label="<i class=\'fui-check\'>ON</i>" data-off-label="<i class=\'fui-cross\'>OFF</i>">' + "<input class='new-filter' type='checkbox' value='" + valGroup + "' name='filterCookie' /></div></div>");
            modifFilterPersoMap();
            killFilterMap();
        }
        manageCollisionFilter();
        modifFilter();
        $('.switch-new')['bootstrapSwitch']();
        $('.switch-new').removeClass('switch-new');
    }
    tabValue = valGroup.split('-');
    index = tabValue.length - 1;
    idGroup = tabValue[index];
    $(".a-group-"+idGroup).attr('data-content', labelGroup);
    $('#'+homeId+'-'+idGroup).html(labelGroup);
    cssTop = parseInt($('#'+homeId+'-'+idGroup).parent().parent().css('top')) + 30;
    $('#'+homeId+'-'+idGroup).parent().parent().css('top', cssTop);
};

window.joinGroup = function (urlJoinGroup, homeId, groupId, labelGroup, textLoading, textAddFilters, textJoinGroup, isProfile) {
    $('#'+homeId+'-'+groupId).html("<p>"+labelGroup+"</p><button class='btn btn-xs btn-primary'>"+textLoading+"</button>");

    $.get(urlJoinGroup, function() {})
        .done(function() {
            arg1 = JSON.stringify('group-' + labelGroup.replace("'", "&perso;") + '-' + groupId);
            arg2 = JSON.stringify(labelGroup.replace("'", "&perso;"));

            // Current
            if (isProfile)
                $('#' + homeId + '-' + groupId).html("<p>" + labelGroup + "<br />" + textJoinGroup + "</p>");
            else
                $('#' + homeId + '-' + groupId).html("<p>" + labelGroup + "<br />" + textJoinGroup + "</p><button class='btn btn-xs btn-primary' onclick='addGroupFilter(" + arg1 + ", " + arg2 + ", " + homeId + ")'>" + textAddFilters + "</button>");
            // All
            $('.group-' + groupId).removeClass("grayscale");
            if (isProfile) {
                $(".a-group-" + groupId).attr('data-content', "<p>" + labelGroup + "<br />" + textJoinGroup + "</p>");
                $("#" + homeId + "-" + groupId + "-popover").popover('show');
            } else {
                $(".a-group-" + groupId).attr('data-content', "<p>" + labelGroup + "<br />" + textJoinGroup + "</p><button class='btn btn-xs btn-primary' onclick='addGroupFilter(" + arg1 + ", " + arg2 + ", " + homeId + ")'>" + textAddFilters + "</button>");
                $("#" + homeId + "-" + groupId + "-popover").popover('show');
            }
        });
}
