var initCarousel = function () {
    // Carousel Lazy-loading
    $('[data-ride="carousel"]').on('slide.bs.carousel', function (e) {
        var $this = $(this);
        var $next = $(e.relatedTarget).find('img');
        if ($next) {
            $next.attr('src', $next.attr('lazy-src'));
            $next.removeAttr('lazy-src');
        }
    }).trigger('slid.bs.carousel');

    // Carousel keyboard controls
    if ($('[data-ride="carousel"]').length === 1) {
        $(document).on('keyup', function (e) {
            if(e.which == 39){
                $('[data-ride="carousel"]').carousel('next');
            } else if(e.which == 37){
                $('[data-ride="carousel"]').carousel('prev');
            }
        });
    }
};

initCarousel();
