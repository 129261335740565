import i18n from '../../../../src/i18n';
$(document).ready(function() {

    if($("#social").length > 0){
        facebookConnect();
    }

    /******************* PROFIL PAGE *********************/
    if($("#recherches_user").length > 0){
        deleteSavedSearch();
    }

    // When I arrive on the edit page from the "Forgot my password" email, focus on the password input
    if ($.urlParam('helpPassword')) {
        openPasswordField();
    }
});

window.deleteSavedSearch = function (){
    $(".delete-alert").click(function(e){
        e.preventDefault();
        var id = $(this).attr("data-id");
        $.ajax({
            type: "GET",
            url: $(this).attr("href"),
            success: function(msg){
                $(".delete-"+id).parent().parent().remove();
                $("#modal-delete-search").modal("hide");
            }
        });
    });

    $('.btn-toggle').click(function() {
        $(this).find('.btn').toggleClass('active');
        $(this).find('.btn').toggleClass('btn-default');
    });

    $('.delete-alert').click(function() {
        $(this).closest("tr").css('display', 'none');
    });

    $(".delete-search-modal").unbind("click").click(function(e){
        e.preventDefault();
        $("#modal-delete-search .delete-alert").attr("href",$(this).attr("data-url"));
        $("#modal-delete-search .delete-alert").attr("data-id",$(this).attr("data-id"));
    });
}

// change the userdescription by locale (use in home view)
window.changeUserDescriptionViewLink = function (element) {
    var localeToDisplay = element.attr('data-locale');

    $('.described_language .change-description').removeAttr('data-select');
    element.attr('data-select', '');

    $('#group-btn-translate-user').show();
    $('#group-btn-translate-user .translate-user').show();
    $('#group-btn-translate-user .text-original-user').hide();

    // change the description in locale selected
    $('#description').html($('#description-'+localeToDisplay).html());
    $('#tidiness').html($('#tidiness-'+localeToDisplay).html());
    $('#planning').html($('#planning-'+localeToDisplay).html());
}

// change the homedescription by locale (use in home view) NOT BUTTON
window.changeHomeDescriptionViewLink = function (element) {
    var localeToDisplay = element.attr('data-locale');

    $('.described_language_home .change-description').removeAttr('data-select');
    element.attr('data-select', '');

    $('#group-btn-translate-home').show();
    $('#group-btn-translate-home .translate-home').show();
    $('#group-btn-translate-home .text-original-home').hide();

    // change the description in locale selected
    $('#title').html($('#title-'+localeToDisplay).html());
    $('#good_feature').html($('#good_feature-'+localeToDisplay).html());
    $('#good_place').html($('#good_place-'+localeToDisplay).html());
    $('#other').html($('#other-'+localeToDisplay).html());
    $('#description_type').html($('#description_type-'+localeToDisplay).html());
}

window.openPasswordField = function () {
    var $passwordFieldButton = $('.password_edit a.button_edit');

    if ($passwordFieldButton && $passwordFieldButton.length > 0) {
        edit($passwordFieldButton);
        setTimeout(function() {
           $('#password').focus();
        }, 0);
    }
}

window.seeMore = function() {
    var seemore = document.querySelector('.seemore');
    seemore.innerText = seemore.innerText === i18n.t('see_more') ? i18n.t('common:see_less') : i18n.t('see_more');
    var hiddenElements = document.querySelectorAll('.hidden-tag')

    if(hiddenElements.length > 9)
    {
        var count = hiddenElements.length;
        var i =0;

        var isRemoveClass = document.querySelectorAll('.hidden-tag.hide').length

        for(i; i<count; i++) {
            if(isRemoveClass) {
                hiddenElements[i].classList.remove('hide')
            } else {
                hiddenElements[i].classList.add('hide')
            }
        }
    }
}
