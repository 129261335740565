import i18n from '../../../../src/i18n';

$(document).ready(function() {
    const collapseHeader = function() {
        // Function to only have on collapse open in the header (search or burger menu)
        const $collapseItems = $('#navbar-collapse, #navbar-collapse-search')
        $collapseItems.on('show.bs.collapse', () => {
            $collapseItems.collapse('hide');
        });

        $('#navbar-collapse-search').on('shown.bs.collapse', () => {
            // Add focus on the search input
            const $input = $('#navbar-collapse-search #autocomplete')
            $input.length > 0 && $input.focus();
        });
    };

    if($(".signup-top").length == 0){
        topBar();
    }

    /******************** IF THERE IS NO USER LOGGED *********************/
    if($(".signup-top").length > 0){
        $("#associate-account").unbind("click").click(function(e){
            $("#modalLogin .main-body-modal").show();
            $("#modalLogin .modal-choose").toggleClass("hide");
            $("#titleAssociate").addClass("hide");
            $("#titleAssociateLogin").removeClass("hide");
            $("#modalLogin .modal-footer.footer-login").toggleClass("hide");
            $("#submitbutton").addClass("hide");
            $("#submitbuttonAssociate").removeClass("hide");
        });
        $("#backAssociate").unbind("click").click(function(e){
            $("#modalLogin .main-body-modal").hide();
            $("#modalLogin .modal-choose").toggleClass("hide");
            $("#titleAssociate").removeClass("hide");
            $("#titleAssociateLogin").addClass("hide");
            $("#modalLogin .modal-footer.footer-login").toggleClass("hide");
            $("#submitbutton").addClass("hide");
            $("#submitbuttonAssociate").removeClass("hide");
        });
        facebookConnect();
        handleGroupHeadband();
    }

    collapseHeader();
    handleMessageTabs();
    changeLinkTopMenuMessage();
    handleMaxHeight();
    handleMobileHover();
});

const showHome100Popup = function(homeId) {
    const content = (
        <div>
            {i18n.t('upload:modal_fillingWriteHome_success')}<br/><br/>
            {i18n.t('upload:modal_fillingWriteHome_firstExchange')}<br/><br/>
            {i18n.t('upload:modal_fillingWriteHome_firstExchangeExplain')}<br/>
            {i18n.t('upload:modal_fillingWriteHome_messages')}
        </div>
    );
    sweetAlert({
        title: i18n.t('upload:modal_fillingWrite_title'),
        content,
        buttons: i18n.t('upload:modal_fillingWriteHome_confirm'),
        icon: "success",
        closeOnClickOutside: true
    }).then(() => {
        window.location.href = i18n.t('search:search_url', { slug: 'everywhere' });
    });
};

const showProfil100Popup = function(userId, firstHomeId, userHadHomeFullFilledOnce) {
    if (userHadHomeFullFilledOnce) {
        const content = (
            <div>
                {i18n.t('upload:modal_fillingWriteHome_profileSuccess')}<br/><br/>
                {i18n.t('upload:modal_fillingWriteProfile_continueBrowse')}<br/>
                {i18n.t('upload:modal_fillingWriteHome_messages')}
            </div>
        );
        sweetAlert({
            title: i18n.t('upload:modal_fillingWrite_title'),
            content,
            buttons: i18n.t('upload:modal_fillingWriteProfile_browseHomes'),
            icon: "success",
            closeOnClickOutside: true
        }).then(() => {
            window.location.href = i18n.t('search:search_url', { slug: 'everywhere' });
        });
    } else {
        let url = i18n.t('home:home.create.url');
        let confirm = i18n.t('upload:modal_fillingWriteProfile_createHome');
        if (firstHomeId) {
            url = i18n.t('home:home.edit.url', { id: firstHomeId });
            confirm = i18n.t('upload:modal_fillingWriteProfile_fillHome');
        }
        const content = (
            <div>
                {i18n.t('upload:modal_fillingWriteHome_profileSuccess')}<br/><br/>
                {i18n.t('upload:modal_fillingWriteProfile_completeHome')}
            </div>
        );
        sweetAlert({
            title: i18n.t('upload:modal_fillingWrite_title'),
            content,
            buttons: confirm,
            icon: "success",
            closeOnClickOutside: true
        }).then(() => {
            window.location.href = url;
        });
    }
};

function topBar(){
    var dragging = false;

    // Fix for double tap issue on iOS devices: https://www.nczonline.net/blog/2012/07/05/ios-has-a-hover-problem/
    $('body').on('touchmove', function(){
        dragging = true;
    });

    $('body').on('touchstart', function(){
        dragging = false;
    });

    $('body').on('touchend', '#navbar-collapse .dropdown-menu a', function(e) {
        if (dragging) {
            return;
        }
        var link = $(this).attr('href');
        if (link) {
            window.location = link;
        }
    });

    $('#close-headband').click(function(){
        $('#verification-headband').remove();
        var headbandCookie = JSON.parse(decodeURIComponent(readCookie("headband")));
        if (headbandCookie) {
            headbandCookie.isClosed = true;
            createCookie("headband", encodeURIComponent(JSON.stringify(headbandCookie)), 365);
        }
    });
}

function manageMarginHeadBand(){
    console.warn('Used somewhere ?');

    $("#head-nav-band").height();
}

function handleMessageTabs() {
    $('.menu-messages-link a').click(function(e) {
        e.preventDefault();
        $(this).tab('show');
    });
}

function changeLinkTopMenuMessage()
{
    $('.menu-messages-link').click(function(e) {
        const status = $(this).data("status");
        const grab = "#link-bottom-top-menu-message".concat(status);
        $(grab).attr('style', "");
        if (status == 3)
        {
            $("#link-bottom-top-menu-message7").attr('style', "display:none;");
            $("#link-bottom-top-menu-message6").attr('style', "display:none;");
        }
        else if (status == 6)
        {
            $("#link-bottom-top-menu-message3").attr('style', "display:none;");
            $("#link-bottom-top-menu-message7").attr('style', "display:none;");
        }
        else
        {
            $("#link-bottom-top-menu-message3").attr('style', "display:none;");
            $("#link-bottom-top-menu-message6").attr('style', "display:none;");
        }
    });
}

// update the badge nb in topmenuprofil
window.updateTopMenuBadge = function (badgeNb) {
    $('#badge-profil').attr('data-value', badgeNb);

    if (badgeNb == 0) {
        $('#badge-profil').css('display', 'none');
    } else {
        $('#badge-profil').css('display', 'block');
        $('#badge-profil').html(badgeNb);
    }
}

// update the badge for GP menu
window.updateGPMenuBadge = function (badgeValue) {
    const badge = document.querySelector('#badge-gp');
    const innerBadge = badge.querySelector('span');

    if (badgeValue == 0) {
        badge.classList.add('hidden');
    } else {
        innerBadge.innerHTML = badgeValue;
        badge.classList.remove('hidden');
    }
}

function handleGroupHeadband() {
    $('#close-group-headband').click(function() {
        $('#group-headband').remove();
    });
}

function handleMaxHeight() {
    resizeDropdown();
    $(window).on('resize', resizeDropdown);
    $('.dropdown').on('touchstart', resizeDropdown);
}

function resizeDropdown() {
    var maxHeight = $(window).height() - $('#head-nav-band').height();
    $('#head-nav-band .dropdown-menu').css({
        'max-height': maxHeight,
        'overflow-y': 'auto'
    });
}

function handleMobileHover() {
    if ($(document.documentElement).hasClass('no-support-hover')) {
        var $headerDropdowns = $('#head-nav-band .navbar-layout-login li.dropdown');
        var $body = $('body');
        // For devices that don't handle hover (smartphones or tablets)
        $headerDropdowns.on('click', function(e) {
            var $this = $(this);
            var isOpened = $this.hasClass('opened');

            if ($(e.target).closest('.dropdown-menu').length > 0) {
                // We clicked on an item in the submenu
                return;
            }

            $headerDropdowns.removeClass('opened');

            if (!isOpened) {
                $this.addClass('opened');
                $body.addClass('noscroll');
            } else {
                $body.removeClass('noscroll');
            }
        });

        // Close dropdown when tapping outside (scroll for example)
        $(document).on('click, touchstart', function(e) {
            if ($(e.target).closest('#head-nav-band .navbar-layout-login li.dropdown').length === 0) {
                $headerDropdowns.removeClass('opened');
                $body.removeClass('noscroll');
            }
        });
    }
}
