$(document).ready(function() {

    /********************* STEP "FIND YOUR FIRENDS" **********************/
    if($("#from-social-friends").length > 0){
        addMultipleFriends();
    }

    /****************** FRIENDS PAGE *******************/
    if($("#searchFriend").length > 0){
        facebook();
    }

    /******************* People you might know area *********************/
    $('#next').click(function(e) {
        e.preventDefault();
        $(".active").next('.recommandation-div').addClass('active').css('display','block');
        $(".active").prev('.active').removeClass('active').css('display','none');
    });

    $('#prev').click(function(e) {
        e.preventDefault();
        $(".active").prev('.recommandation-div').addClass('active').css('display','block');
        $(".active").next('.active').removeClass('active').css('display','none');
    });

    addFriendSide();
});

function addFriendSide()
{
    $("#accept-friend").click(function (e) {
        var on = 'acceptFriendSide('.concat($(this).data("friend")).concat(')');
        $("#buttonAddFriendTop").attr("onClick", on);
    });
}

function addFriend(){
    console.warn('Used somewhere ?');

    $(".acceptFriend").click(function(e){
        $('#modalAddFriend span.name').text($(this).parent().find(".media-heading").text());
        $('#buttonAddFriend').attr("data-id",$(this).attr("data-id"));
    });

    $('#buttonAddFriend').click(function (e) {
        $.ajax({
            url: $(this).attr("data-link"),
            data: {
                id: $(this).attr("data-id"),
                message: $('#friendMessage').val()
            },
            dataType: "json",
            type: "POST",
            success: function (data) {
                if (data.success == true) {
                    $('#modalAddFriend').modal('hide');
                    $('#buttonAddFriend').remove();
                } else {
                    alert($('#modalAddFriend p.hide').text());
                }
            }
        });
    });
}

/**
* Used when we want to add multiple friends with checkbox
*/
function addMultipleFriends(){
    $('#addMultipleFriends').click(function (e) {
        var ids = [];
        $(".acceptFriend:checked").each(function(){
            ids.push($(this).attr("data-id"));
        });
        $.ajax({
            url: $(this).attr("data-link"),
            data: {
                id: ids,
                message: $('#friendMessage').val()
            },
            dataType: "json",
            type: "POST",
            success: function (data) {
                $('#modalAddFriend').modal('hide');
                $('#buttonAddFriend').remove();
                window.location = $("#next").attr("href");
            }
        });
    });

    $(".check-all").unbind("click").click(function(e){
        $(".acceptFriend").prop("checked",true);
        $('#next').hide();
        $('#addFriends').show();
    });

    $(".uncheck-all").unbind("click").click(function(e){
        $(".acceptFriend:checked").prop("checked",false);
        $('#next').show();
        $('#addFriends').hide();
    })

    $(".acceptFriend").on("change", function() {
        if ($(".acceptFriend:checked").length > 0) {
            $('#next').hide();
            $('#addFriends').show();
        } else {
            $('#next').show();
            $('#addFriends').hide();
        }
    });
}

// change the friend page on user view
window.changeFriendPageUserView = function (page) {
    var url  = $(".friend-pagination").attr('data-url');
    var userId = $(".friend-pagination").attr('data-id');

    $('.friendsList-public').css('display', 'none');
    $('#loading_friends').css('display', 'block');

    $.ajax({
        url: url,
        data: {
            page: page,
            userId: userId
        },
        dataType: "html",
        type: "POST",
        success: function (data) {
            $('.friendsList-public').html(data);
            $('.friendsList-public').css('display', 'block');
            $('#loading_friends').css('display', 'none');
        },
        error: function (){
            $('.friendsList-public').css('display', 'block');
            $('#loading_friends').css('display', 'none');
        }
    });
}

window.acceptFriendSide = function acceptFriendSide(friend_id) {
    var url = $('#modalAddFriendTop').data("url");
    var name = $('#friend-name-'+friend_id).html();
    var str = $('#accept-friend').data("successmessage");
    var deny  = $('#accept-friend').data("errormessage");
    if (!$('#modalAddFriendTop').data("message") || !url) {
        return;
    }
    $('#modalAddFriendTop').modal('hide');
    $('#accept-friend').hide();
    $('#load-accept-friend').css('display', 'block');
    $.ajax({
        url: url,
        data: {
            id: friend_id,
        },
        dataType: "json",
        type: "POST",
        success: function(){
            changeNbRequest();
            $('#load-accept-friend').hide();
            $('#friend-accepted').text(str);
        },
        error: function(){
            $('#load-accept-friend').hide();
            $('#friend-accepted').text(deny);
        }
    });
}
